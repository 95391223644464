var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "test" }, [
    _vm.useWrapperValidations
      ? _c(
          "div",
          {
            ref: "slotParent",
            on: {
              input: function ($event) {
                return _vm.validate()
              },
            },
          },
          [
            _vm._t("default", null, {
              state: !(_vm.showErrors && _vm.errorText),
            }),
            _vm.showErrors && _vm.errorText
              ? _c("span", { staticClass: "validation-wrapper-error" }, [
                  _vm._v(_vm._s(_vm.errorText)),
                ])
              : _vm._e(),
          ],
          2
        )
      : _c(
          "div",
          [
            _vm._t("default", null, { state: !_vm.showValidationError }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showValidationError,
                    expression: "showValidationError",
                  },
                ],
                staticClass: "validation-wrapper-error",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.individualField?.validationError) +
                    "\n    "
                ),
              ]
            ),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }