<template>
  <div class="test">
    <!-- Display ValidationWrapper errors if useWrapperValidations is set in store. -->
    <div v-if="useWrapperValidations" ref="slotParent" @input="validate()">
      <slot :state="!(showErrors && errorText)" />
      <span v-if="showErrors && errorText" class="validation-wrapper-error">{{ errorText }}</span>
    </div>

    <!-- Display SchemaFormField errors if not set. -->
    <div v-else>
      <slot :state="!showValidationError" />
      <span v-show="showValidationError" class="validation-wrapper-error">
        {{ individualField?.validationError }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ValidationWrapper',
  props: {
    rule: {
      type: String,
      default: '',
    },
    field: Object,
    childField: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      slotElement: null,
      errorText: null,
    }
  },
  computed: {
    ...mapGetters('schema',
      {
        useWrapperValidations: 'useWrapperValidations',
        validationResults: 'wrapperValidationObservers',
        showErrors: 'showWrapperValidationErrors',
      }
    ),
    rules() {
      return `${this.fieldRequired ? 'required|' : ''}${this.rule.replace('required', '')}`
    },
    individualField() {
      return this.childField ? this.field.children.find(child => child.name === this.childField) : this.field
    },
    fieldRequired() {
      return this.individualField.required || this.rule.includes('required')
    },
    showValidationError() {
      return this.individualField?.validationError &&
        this.individualField?.dirty &&
        !this.useWrapperValidations
    },
  },
  watch: {
    field: {
      deep: true,
      handler() {
        this.validate()
      },
    },
  },
  mounted() {
    this.validate()
  },
  beforeDestroy() {
    if (!this.useWrapperValidations) return
    this.forceValid()
  },
  methods: {
    ...mapActions('schema', [
      'updateWrapperValidationObservers',
    ]),
    async validate() {
      if (!this.useWrapperValidations) return

      await this.$nextTick()

      const hidden = document.querySelectorAll(`[data-field-path="${this.field.path}"]`)[0].closest('.schema-field__hidden')
      if (hidden) {
        this.forceValid()
        return
      }

      switch(this.getSlotInputType()) {
        case 'text':       await this.validateTextElement(); break
        case 'number':     await this.validateNumberElement(); break
        case 'date':       await this.validateDateElement(); break
        case 'select-one': await this.validateSelectElement(); break
        case 'radio':      await this.validateRadioElement(); break
        default: break
      }

      this.updateWrapperValidationObservers({
        observerId: this.individualField.path,
        valid: !this.errorText,
      })
    },
    forceValid() {
      if (this.individualField.path in this.validationResults) {
        this.updateWrapperValidationObservers({
          observerId: this.individualField.path,
          valid: true,
        })
      }
    },
    getSlotInputType() {
      // Find the type of the input in the slot to validate by.
      this.slotElement = this.$refs.slotParent?.children[0]
      let type = this.slotElement.type

      // Handle edge cases where our top level slot element isn't an input.
      if (this.slotElement.type === undefined) {
        if (this.slotElement.role === 'radiogroup') { type = 'radio' }
      }

      return type
    },
    ruleData(rule) {
      switch(rule) {
        case 'phone_number':
          return {
            regex: new RegExp(/^$|^\d{10,}$/),
            error: 'Input must be only digits and at least 10 characters long',
          }
        case 'zipcode':
          return {
            regex: new RegExp(/^$|^\d{5}([-\s]\d{4})?$/, 'g'),
            error: 'Zipcode must follow format ##### or #####-####',
          }
        case 'ssn':
          return {
            regex: new RegExp(/^$|^(\d{3}-?\d{2}-?\d{4})$/, 'gi'),
            error: 'Invalid SNN, must be in XXX-XX-XXXX format',
          }
        case 'email':
          return {
            regex: new RegExp(/^$|[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z-]*[a-z])+/, 'gi'),
            error: 'Must be a valid email address',
          }
        case 'number':
          return {
            regex: null,
            error: 'This field amount is invalid',
          }
        case 'checkbox':
          return {
            regex: null,
            error: 'Invalid selection',
          }
        case 'percentage':
          return {
            regex: new RegExp(/^(?:[1-9][0-9]?|100)$/),
            error: 'Must be an integer in the range 1-100',
          }
        case 'required':
          return {
            regex: new RegExp(/\S+/),
            error: 'This field is required',
          }
      }
    },
    validateUsingFieldValidations(value) {
      let valid = true

      // validation block from form.js modified to fit here
      for (let [key, val] of Object.entries(this.field.validations)) {
        let regex = key
        let msg = val

        // Not sure where this is helpful
        if (typeof(val) === 'object') {
          regex = val.regex
          msg = key
        }

        let match
        // It seems that interesting regex strings that are passed in will need to be converted
        let flags = regex ? regex.replace(/.*\/([gimy]*)$/, '$1') : ''
        if (flags && flags !== regex) {
          let pattern = regex.replace(new RegExp('^/(.*?)/'+flags+'$'), '$1')

          match = value && value.match(new RegExp(pattern, flags))
        } else {
          match = value && value.match(new RegExp(regex))
        }

        if (!match) {
          this.errorText = msg
          valid = false
        }
      }
      if (valid) this.errorText = null
    },
    validateTextElement() {
      let valid = true

      if (this.rules) {
        // Iterate over our rules and set our error to the first rule that fails validation.
        this.rules.split('|').every(rule => {
          if (!rule) return true

          const ruleData = this.ruleData(rule)

          valid = ruleData.regex.test(this.slotElement.value)

          if (!valid) this.errorText = ruleData.error

          return valid
        })
      }

      if (valid) this.errorText = null
    },
    validateNumberElement() {
      const noValueSelected = isNaN(this.slotElement.valueAsNumber)
      const invalidSelection = this.slotElement.valueAsNumber <= 0

      if (noValueSelected) {
        this.errorText = this.fieldRequired ? this.ruleData('required').error : null
      } else if (invalidSelection) {
        this.errorText = this.ruleData('number').error
      } else {
        this.errorText = null
      }
    },
    validateDateElement() {
      const normalizeDate = (date) => {
        if (date && date !== '') {
          const tempDateTime = new Date(date)
          const [tempYear, tempMonth, tempDate] = date.split('-')
          return tempDateTime.getFullYear().toString().length === 4 ? [tempMonth, tempDate, tempYear].join('/') : null
        } else {
          return null
        }
      }

      this.validateUsingFieldValidations(normalizeDate(this.slotElement.value))
    },
    validateSelectElement() {
      const noValueSelected = this.slotElement.selectedIndex === -1
      this.errorText = noValueSelected && this.fieldRequired
        ? this.ruleData('required').error
        : null
    },
    validateRadioElement() {
      // Unselected radios will be undefined before form validation and set to the default
      // empty string after, so check for both here in case.
      const noValueSelected = this.field.value == null
      this.errorText = noValueSelected && this.fieldRequired
        ? this.ruleData('required').error
        : null
    },
  },
}
</script>

<style lang="scss">

.validation-wrapper-error {
  color: red;
  font-size: 0.75em;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background: $ct-ui-primary !important;
  border-color: $ct-ui-primary !important;
}
</style>
